import React, { PureComponent } from 'react'
import Img from 'gatsby-image'
// import styled from 'styled-components'
// import media from 'styled-media-query'
import ImageGallery from 'react-image-gallery'

class OfferGallery extends PureComponent {
  _renderItem = item => (
    <div className="image-gallery-image">
      <Img
        fluid={item.originalFluid}
        title={item.originalTitle}
        alt={item.originalAlt}
      />
      {item.description && (
        <span className="image-gallery-description">{item.description}</span>
      )}
    </div>
  )

  render() {
    const { images, className } = this.props
    const items = images.map(({ image }) => ({
      originalFluid: image.localFile.childImageSharp.fluid,
      originalAlt: image.alt,
    }))

    return (
      <ImageGallery
        additionalClass={className}
        items={items}
        renderItem={this._renderItem}
        showThumbnails={false}
        showPlayButton={false}
      />
    )
  }
}

export default OfferGallery
