import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import SEO from '../components/seo'
import OfferGallery from '../components/OfferGallery'

// TODO: This should be a separate component it's used on 3? subpages
const Container = styled.div`
  max-width: 1000px;
  width: 100%;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.blackOverlay};
  margin: 30px 0;
  padding: 40px;
  flex: none;
  display: flex;
  flex-direction: column;

  ${media.lessThan('small')`
    margin:0;
    padding: 20px;
  `}
`

const OfferHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(1.45rem + 20px);

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

const CarImages = styled(OfferGallery)`
  flex: 0 0 45%;
  max-width: 415px;
  ${media.lessThan('medium')`
    order: 2;
  `}
`

const MainDataContainer = styled.div`
  display: flex;
  flex: 0 0 45%;
  flex-direction: column;
  justify-content: flex-start;

  ${media.greaterThan('medium')`
    padding-top: 1.45rem;
  `}

  ${media.lessThan('small')`
    flex: none;
  `}
`

const InfoText = styled.p`
  color: #a5a5a5;
  margin-bottom: 0;
`

const SectionTitle = styled.h2`
  color: #a5a5a5;
`

const Section = styled.section`
  width: 100%;
  margin-bottom: 20px;
`

const TableCell = styled.td`
  padding-top: 7px;
  padding-bottom: 7px;
  border: none;
`

const OfferTemplate = ({
  data: {
    allPrismicOffer: {
      edges: [
        {
          node: {
            data: {
              name,
              model,
              price,
              images,
              specification,
              comments,
              description,
              equipment,
              additional_equipment,
            },
          },
        },
      ],
    },
    allPrismicSale: {
      edges: [
        {
          node: {
            data: { offer_currency },
          },
        },
      ],
    },
  },
}) => (
  <>
    <SEO title={name} />
    {/* TODO: SEO, handle it correctly - use offer description
      and add some keywords based on the model, name, etc. */}
    <Container>
      {/* Some beter name for that lol? */}
      <OfferHeader>
        <CarImages images={images} />
        <MainDataContainer>
          <h1>{name}</h1>
          <InfoText>
            <FormattedMessage id="model" />:
          </InfoText>
          <h2>{model}</h2>
          {/* <InfoText>
            <FormattedMessage id="price" />:
          </InfoText>
          <h3>
            <FormattedNumber
              value={price}
              style="currency" // eslint-disable-line react/style-prop-object
              currency={offer_currency}
            />
          </h3> */}
        </MainDataContainer>
      </OfferHeader>
      <Section>
        <SectionTitle>
          <FormattedMessage id="description" />
        </SectionTitle>
        <div dangerouslySetInnerHTML={{ __html: description.html }} />
      </Section>
      <Section>
        <SectionTitle>
          <FormattedMessage id="specification" />
        </SectionTitle>
        <table>
          <tbody>
            {specification.map(({ key, value }, index) => (
              <tr key={index}>
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
      <Section>
        <SectionTitle>
          <FormattedMessage id="equipment" />
        </SectionTitle>
        <div dangerouslySetInnerHTML={{ __html: equipment.html }} />
      </Section>
      <Section>
        <SectionTitle>
          <FormattedMessage id="additionalEquipment" />
        </SectionTitle>
        <table>
          <tbody>
            {additional_equipment.map(({ feature, price }, index) => (
              <tr key={index}>
                <TableCell>{feature}</TableCell>
                <TableCell>
                  <FormattedNumber
                    value={price}
                    style="currency" // eslint-disable-line react/style-prop-object
                    currency={offer_currency}
                  />
                </TableCell>
              </tr>
            ))}
          </tbody>
        </table>
      </Section>
      <Section>
        <SectionTitle>
          <FormattedMessage id="comments" />
        </SectionTitle>
        <div dangerouslySetInnerHTML={{ __html: comments.html }} />
      </Section>
    </Container>
  </>
)

export default OfferTemplate

export const query = graphql`
  query($id: String!, $locale: String!) {
    allPrismicOffer(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          data {
            name
            model
            price
            images {
              image {
                alt
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
            comments {
              html
            }
            description {
              html
            }
            specification {
              key
              value
            }
            equipment {
              html
            }
            additional_equipment {
              feature
              price
            }
          }
        }
      }
    }
    allPrismicSale(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            offer_currency
          }
        }
      }
    }
  }
`
